import React, {Component} from "react";
import {ValidatorForm, TextValidator} from "react-material-ui-form-validator";

import {Cookies} from "react-cookie";

import gql from "graphql-tag";
import {ApolloConsumer} from "react-apollo";
import moment from "moment";

import {
  Typography,
  withStyles,
  Dialog,
  DialogActions,
  Checkbox,
  Link,
  FormControlLabel,
  SnackbarContent,
  Snackbar,
  Icon,
  IconButton,
} from "@material-ui/core";

import WarningIcon from "@material-ui/icons/Warning";

const cookies = new Cookies();

import muiStyles from "./muiStyles";
import CustomButton from "./customButton";

const LOGIN_QUERY = gql`
  query login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      jwtToken
    }
  }
`;

class Login extends Component {
  constructor() {
    super();
    this.state = {
      formData: {
        email: "",
        password: "",
        permanent: true,
      },
      submitted: false,
      dialogLogin: false,
      snackError: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.setState({dialogLogin: window.location.href.indexOf("login") > -1});
  }

  handleChange(event) {
    const {formData} = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({formData});
  }

  handleToggle(event) {
    const {formData} = this.state;
    console.log(formData);
    formData[event.target.name] = !formData[event.target.name];
    console.log(formData);
    this.setState({formData});
  }

  handleClose() {
    this.setState({dialogLogin: false});
  }

  handleOpen() {
    this.setState({dialogLogin: true});
  }

  handleSubmit(e, client) {
    e.preventDefault();
    const {formData} = this.state;
    client
      .query({
        query: LOGIN_QUERY,
        variables: {email: formData.email, password: formData.password},
      })
      .then(({data}) => {
        console.log("submitreturn");
        console.log(data);

        if (formData.permanent) {
          cookies.set("token", data.login.jwtToken || "", {
            domain: "whatsinmymeds.pro",
            path: "/",
          });
        } else {
          cookies.set("token", data.login.jwtToken || "", {
            domain: "whatsinmymeds.pro",
            path: "/",
            expires: moment().add(2, "hours").toDate(),
          });
        }

        setTimeout(() => (window.location = this.appLocation()), 500);
      })
      .catch(({graphQLErrors}) => {
        if (graphQLErrors && graphQLErrors.length > 0) {
          this.setState({snackError: true});
          setTimeout(() => this.setState({snackError: false}), 10000);
          console.log("Error: ", graphQLErrors);
        }
      });
  }

  appLocation() {
    if (window.location.host === "lp-staging.whatsinmymeds.pro") {
      return "https://app-staging.whatsinmymeds.pro";
    } else if (
      window.location.host === "www.whatsinmymeds.pro" ||
      window.location.host === "whatsinmymeds.pro"
    ) {
      return "https://app.whatsinmymeds.pro";
    } else {
      return "http://localhost:3000";
    }
  }

  render() {
    const {classes} = this.props;
    const {formData, submitted} = this.state;
    console.log(this.state);

    return (
      <ApolloConsumer>
        {(client) => (
          <div>
            <Snackbar
              open={this.state.snackError}
              anchorOrigin={{horizontal: "left", vertical: "bottom"}}>
              <SnackbarContent
                className={classes.error}
                message={
                  <span id="client-snackbar" className={classes.message}>
                    <WarningIcon className={classes.icon} />
                    {
                      "Der Login-Versuch hat leider nicht geklappt. Stimmt das Passwort?"
                    }
                  </span>
                }
              />
            </Snackbar>

            <Dialog
              open={this.state.dialogLogin}
              onClose={() => this.handleClose()}
              BackdropProps={{classes: {root: classes.backdrop}}}
              PaperProps={{classes: {root: classes.paper}}}>
              <ValidatorForm
                onError={(errors) => console.log(errors)}
                onSubmit={() => {}}>
                <div className={classes.dialogContent}>
                  <Typography variant="h2" className={classes.h2}>
                    Login
                  </Typography>
                  <Typography variant={"body1"}>
                    Um WhatsIn Professional zu nutzen, loggen Sie sich bitte mit
                    Ihrer E-Mail-Addresse und Ihrem Zugangscode ein. Falls Sie
                    den Zugangscode vergessen haben, können Sie unter{" "}
                    <Link href="mailto:max@whatsinmymeds.de">
                      max@whatsinmymeds.de
                    </Link>{" "}
                    einen neuen Code anfordern.
                  </Typography>

                  <TextValidator
                    className={classes.input}
                    label="Email"
                    onBlur={this.handleBlur}
                    onChange={(event) => this.handleChange(event)}
                    name="email"
                    InputProps={{className: classes.emailInput}}
                    InputLabelProps={{className: classes.emailLabel}}
                    value={formData.email}
                    validators={["required", "isEmail"]}
                    errorMessages={["Pflichtfeld", "Keine gültige E-Mail"]}
                  />
                  <TextValidator
                    label="Zugangscode"
                    className={classes.input}
                    onChange={(event) => this.handleChange(event)}
                    InputProps={{className: classes.emailInput}}
                    InputLabelProps={{className: classes.emailLabel}}
                    name="password"
                    type="password"
                    validators={["required"]}
                    errorMessages={["Pflichtfeld"]}
                    value={formData.password}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="checked"
                        checked={formData.permanent}
                        name="permanent"
                        onChange={(event) => this.handleToggle(event)}
                        color="primary"
                      />
                    }
                    label={<Typography>Angemeldet bleiben</Typography>}
                  />
                  <DialogActions className={classes.dialogActions}>
                    <CustomButton
                      type="submit"
                      disabled={submitted}
                      onClick={(e) => this.handleSubmit(e, client)}
                      autoFocus>
                      Anmelden
                    </CustomButton>
                  </DialogActions>
                </div>
              </ValidatorForm>
            </Dialog>
            {this.state.dialogLogin === false && (
              <CustomButton
                variant={"alternative"}
                onClick={() => this.handleOpen()}>
                Anmelden
              </CustomButton>
            )}
          </div>
        )}
      </ApolloConsumer>
    );
  }
}

export default withStyles(muiStyles)(Login);
