export default theme => ({
    success: {
        backgroundColor: theme.palette.secondary.main,
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: theme.palette.error.light,
    },
    input: {
        width: '100%',
        marginBottom: 10,
    },
    dialogContent: {

        padding: '1em',
        [theme.breakpoints.up('md')]: {
            padding: '4em',
        }
    },
    h2: {
        marginBottom: 10,
    },
    paper: {
        borderRadius: '1.5em',
        margin: '1em',
        [theme.breakpoints.up('md')]: {
            margin: '4em',
        }
    },
    backdrop: {
        backgroundColor: theme.palette.primary.dark,
        opacity: '0.9 !important',
    },
    dialogActions: {
        paddingTop: '1em',
    },
    icon: {
        fontSize: 20,
        opacity: 0.9,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    link: {
        color: theme.palette.text.primary,
        textDecoration: 'underline',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'none'
        }
    }
});