import React, {Component} from "react";
import {ValidatorForm, TextValidator} from "react-material-ui-form-validator";
import gql from "graphql-tag";
import {Mutation} from "react-apollo";

import {
  Typography,
  withStyles,
  Dialog,
  DialogActions,
  Checkbox,
  Link,
  FormControlLabel,
  SnackbarContent,
  Snackbar,
} from "@material-ui/core";

import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";

import CustomButton from "./customButton";
import muiStyles from "./muiStyles";

import terms from "../../assets/files/terms.pdf";
import dataprivacy from "../../assets/files/dataprivacy.pdf";
import license from "../../assets/files/license.pdf";

const SIGNUP_MUTATION = gql`
  mutation trialSign($email: String!) {
    trialSign(user: {email: $email}) {
      id
      email
    }
  }
`;

class Signup extends Component {
  constructor() {
    super();
    this.state = {
      formData: {
        email: "",
        terms: false,
      },
      submitted: false,
      dialogRegistration: false,
      snackSuccess: false,
      snackError: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {}

  handleChange(event) {
    const {formData} = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({formData});
  }

  handleToggle(event) {
    const {formData} = this.state;
    console.log(formData);
    formData[event.target.name] = !formData[event.target.name];
    console.log(formData);
    this.setState({formData});
  }

  handleClose() {
    this.setState({dialogRegistration: false});
  }

  handleOpen() {
    this.setState({dialogRegistration: true});
  }

  handleMutationCompleted(data) {
    this.setState({snackSuccess: true});
    setTimeout(() => this.setState({snackSuccess: false}), 10000);
    this.handleClose();
  }

  handleMutationError() {
    this.setState({snackError: true});
    setTimeout(() => this.setState({snackError: false}), 10000);
  }

  render() {
    const {classes} = this.props;
    const {formData, submitted} = this.state;
    console.log(this.state);
    return (
      <Mutation
        mutation={SIGNUP_MUTATION}
        onError={() => this.handleMutationError()}
        onCompleted={(data) => this.handleMutationCompleted(data)}>
        {(trialSign, {data, loading, error}) => {
          return (
            <div>
              <Snackbar
                open={this.state.snackSuccess}
                anchorOrigin={{horizontal: "left", vertical: "bottom"}}>
                <SnackbarContent
                  className={classes.success}
                  message={
                    <span id="client-snackbar" className={classes.message}>
                      <InfoIcon className={classes.icon} />
                      {
                        "Die Registrierung war erfolgreich. Bitte überprüfen Sie Ihre E-Mails, wir haben Ihnen eine Zugangscode zugeschickt. Sie werden nun zum Login-Bildschirm weitergeleitet. Bitte geben Sie dort Ihren Zugangscode ein."
                      }
                    </span>
                  }
                />
              </Snackbar>
              <Snackbar
                open={this.state.snackError}
                anchorOrigin={{horizontal: "left", vertical: "bottom"}}>
                <SnackbarContent
                  className={classes.error}
                  message={
                    <span id="client-snackbar" className={classes.message}>
                      <WarningIcon className={classes.icon} />
                      {
                        "Die Registrierung hat leider nicht geklappt. Existiert unter dieser E-Mail Adresse evtl. schon ein Account?"
                      }
                    </span>
                  }
                />
              </Snackbar>

              <Dialog
                open={this.state.dialogRegistration}
                onClose={() => this.handleClose()}
                BackdropProps={{classes: {root: classes.backdrop}}}
                PaperProps={{classes: {root: classes.paper}}}>
                <ValidatorForm
                  ref="form"
                  onError={(errors) => console.log(errors)}
                  onSubmit={(e) => {
                    // console.log(this.state.formData);
                    e.preventDefault();
                    trialSign({
                      variables: {
                        email: formData.email,
                      },
                    });
                  }}>
                  <div className={classes.dialogContent}>
                    <Typography variant="h2" className={classes.h2}>
                      Kostenlos testen
                    </Typography>
                    <Typography variant={"body1"}>
                      Nutzen Sie alle Funktionen von WhatsIn Professional für 14
                      Tage <b>kostenfrei</b>. Einfach Felder ausfüllen,
                      registrieren, fertig. Anschließend erhalten Sie einen
                      Zugangscode per E-Mail, mit dem Sie sich einloggen können.
                    </Typography>

                    <TextValidator
                      className={classes.input}
                      ref="email"
                      label="Email"
                      onBlur={this.handleBlur}
                      onChange={(event) => this.handleChange(event)}
                      name="email"
                      InputProps={{className: classes.emailInput}}
                      InputLabelProps={{className: classes.emailLabel}}
                      value={formData.email}
                      validators={["required", "isEmail"]}
                      errorMessages={["Pflichtfeld", "Keine gültige E-Mail"]}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          value="checked"
                          checked={formData.terms}
                          name="terms"
                          onChange={(event) => this.handleToggle(event)}
                          color="primary"
                        />
                      }
                      label={
                        <Typography>
                          {"Ich akzeptiere "}
                          <Link
                            href={terms}
                            variant="body2"
                            target={"_blank"}
                            className={classes.link}>
                            AGB
                          </Link>
                          {", "}
                          <Link
                            href={license}
                            variant="body2"
                            target={"_blank"}
                            className={classes.link}>
                            Lizenzvertrag
                          </Link>
                          {" und "}
                          <Link
                            href={dataprivacy}
                            variant="body2"
                            target={"_blank"}
                            className={classes.link}>
                            Datenschutzhinweise
                          </Link>
                        </Typography>
                      }
                    />
                    <DialogActions className={classes.dialogActions}>
                      <CustomButton
                        type="submit"
                        variant="default"
                        disabled={submitted || formData.terms === false}
                        autoFocus>
                        Registrierung
                      </CustomButton>
                    </DialogActions>
                  </div>
                </ValidatorForm>
              </Dialog>
              {this.state.dialogRegistration === false && (
                <CustomButton
                  variant="default"
                  onClick={() => this.handleOpen()}>
                  Jetzt kostenlos testen
                </CustomButton>
              )}
            </div>
          );
        }}
      </Mutation>
    );
  }
}

export default withStyles(muiStyles)(Signup);
