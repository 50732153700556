import React, {Component} from 'react';

import {
    Button, withStyles
} from '@material-ui/core'

const styles = theme => ({
    default: {
        borderRadius: '1.5em',
        padding: '0.75em 2.5em',
        fontWeight: 600,
        display: 'block',
        fontSize: 18,
        lineHeight: 1,
        marginTop: '3em',
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
        textTransform: 'none',
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.secondary.main,
        },
    },
    alternative: {
        borderRadius: '1.5em',
        padding: '0.75em 2.5em',
        fontWeight: 400,
        display: 'block',
        fontSize: 16,
        lineHeight: 1,
        border: `1px solid ${theme.palette.primary.contrastText}`,
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        textTransform: 'none',
        '&:hover': {
            opacity: 0.8,
            backgroundColor: theme.palette.primary.main,
        },
    },
});

class CustomButton extends Component {


    render() {

        const {classes} = this.props;

        return (
            <Button color={'secondary'}
                    type={this.props.type}
                    className={this.props.variant ? classes[this.props.variant] : classes.default} onClick={this.props.onClick}>
                {this.props.children}
            </Button>

        );
    }
}

export default withStyles(styles)(CustomButton);

