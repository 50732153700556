import React from "react";
import ReactDOM from "react-dom";
import Signup from "./components/signup";
import {MuiThemeProvider, createMuiTheme} from "@material-ui/core/styles";

const colors = {
  purple: "#5C2874",
  purpleLight: "#EADFEF",
  purpleDark: "#411C52",
  buttonGreen: "#68BCAD",
  functionsBlue: "#E7EBF4",
  factsSalmon: "#FFC5C4",
  testamonialRose: "#FFF3EA",
  textWhite: "#FFF",
  textBlack: "#172751",
  iconRed: "#D37877",
};

import {ApolloClient} from "apollo-boost";
import {ApolloProvider} from "react-apollo";
import {ApolloLink} from "apollo-link";
import {createHttpLink} from "apollo-link-http";
import {InMemoryCache} from "apollo-cache-inmemory";
import Login from "./components/login";

const httpLink = createHttpLink({
  uri:
    process.env.NODE_ENV === "production"
      ? "https://api.whatsinmymeds.pro/graphql"
      : "http://localhost:4040/graphql",
});

const middlewareLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      // authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmdWxsTWVtYmVyc2hpcCI6ZmFsc2UsImlkIjoiZGIxMWE2NDItMDQ2MS00NjljLWE0MDYtOGFiMWNmMDNmMGZjIn0.1H92VEUwkWqtV5fha8QwAYoSe7WyYvXKEk2_KNueSKQ",
      authorization: "Bearer " + localStorage.getItem("token") || null,
    },
  });
  return forward(operation);
});

// use with apollo-client
const link = middlewareLink.concat(httpLink);

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
});

const theme = createMuiTheme({
  overrides: {
    MuiButton: {},
  },

  typography: {
    // Use the system font instead of the default Roboto font.
    useNextVariants: true,
    fontFamily: ["Open Sans", "sans-serif"].join(","),
    h1: {
      fontSize: 40,
      fontWeight: 700,
      fontFamily: "Glober",
    },
    h2: {
      fontSize: 40,
      fontWeight: 700,
      fontFamily: "Glober",
    },
    h3: {
      fontSize: 26,
      fontWeight: 700,
      fontFamily: "Glober",
    },
    h4: {
      fontSize: 40,
      fontWeight: 700,
    },
    h5: {
      fontSize: 40,
      fontWeight: 600,
    },
    h6: {
      fontSize: 14,
      color: colors.textWhite,
      fontWeight: 400,
    },
    body1: {
      lineHeight: "2em",
    },
    body2: {
      lineHeight: "2em",
      fontWeight: 400,
    },
    button: {
      backgroundColor: colors.buttonGreen,
      color: colors.textWhite,
    },
  },
  palette: {
    text: {
      primary: colors.textBlack,
    },
    primary: {
      main: colors.purple,
      light: colors.purpleLight,
      dark: colors.purpleDark,
      contrastText: colors.textWhite,
    },
    secondary: {
      main: colors.buttonGreen,
      // light: colors.factsSalmon,
      // dark:  colors.iconRed,
      contrastText: colors.textWhite,
    },
    error: {
      main: colors.iconRed,
      light: colors.factsSalmon,
    },
  },
});

Array.from(document.getElementsByClassName("signup-button")).forEach((el) => {
  ReactDOM.render(
    <ApolloProvider client={client}>
      <MuiThemeProvider theme={theme}>
        <Signup />
      </MuiThemeProvider>
    </ApolloProvider>,
    el
  );
});
ReactDOM.render(
  <ApolloProvider client={client}>
    <MuiThemeProvider theme={theme}>
      <Login />
    </MuiThemeProvider>
  </ApolloProvider>,
  document.getElementsByClassName("header-login-button")[0]
);
